import React, { useState } from 'react';

import Dropdown from 'react-bootstrap/Dropdown';
import { faUserCircle, faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image';

import ROUTES from "utils/routes"
import SignOutButton from 'components/firebase/SignOut';
import { useAuthentication } from 'components/firebase/AuthUserContext';

import "./navbarNew.scss";

type NavBarPropTypes = {
  background?: 'light' | 'dark' | 'transparent',
}

const ProfileDropdown = () => {
  const authUser = useAuthentication()

  return (
    <Dropdown className="login-container">
      <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>

      {authUser ? <NavigationAuth /> : <NavigationNonAuth />}
    </Dropdown>
  )
}

const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <span
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <FontAwesomeIcon  id="account-icon" icon={faUserCircle}/>
  </span>
));

const NavigationAuth = () => (
  <Dropdown.Menu className="login-menu">
    <Dropdown.Item><Link to={ROUTES.ACCOUNT}>Account</Link></Dropdown.Item>
    <Dropdown.Item><SignOutButton/></Dropdown.Item>
  </Dropdown.Menu>
);

const NavigationNonAuth = () => (
  <Dropdown.Menu className="login-menu">
    <Dropdown.Item><Link to={ROUTES.SIGN_IN}>Sign In</Link></Dropdown.Item>
  </Dropdown.Menu>
);


const NavbarNew = (props: NavBarPropTypes) => {
  const {
    background = 'transparent',
  } = props
  const navBarCss = `nav_bar_${background}`
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const onClick = () => setShowMenu(!showMenu);

  return (
    <nav
        className={navBarCss}
    >
      <div id="logo-container">
          <StaticImage src="../../images/refactor/LobbyViewLogo.svg" alt="logo" style={{width: "40px"}}/>
      </div>
      <div className="routing-container">
        <div className="desktop-menu">
          <Link to={ROUTES.ABOUT}>About</Link>
          <Link to={ROUTES.DATA_DOWNLOAD}>Data Download</Link>
          <Link to={ROUTES.QUERY}>Advanced Search</Link>
          <Link to={ROUTES.DATA_VIZ_PAGE}>Data Visualizations</Link>
        </div>
        <ProfileDropdown />
        <FontAwesomeIcon id="menu-icon" icon={faBars} onClick={onClick}/>
        <div id="collapsable-menu" className={showMenu ? 'show' : 'hide'}>
          <Link to={ROUTES.ABOUT}>About</Link>
          <Link to={ROUTES.DATA_DOWNLOAD}>Data Download</Link>
          <Link to={ROUTES.QUERY}>Advanced Search</Link>
          <Link to={ROUTES.DATA_VIZ_PAGE}>Data Visualizations</Link>
        </div>
      </div>
      
    </nav>
  )
}
export default NavbarNew

